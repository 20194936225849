import './GlobalStyles.scss'

const GlobalStyles = ({children}) => {

    return children
}

export default GlobalStyles


