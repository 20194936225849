import classNames from "classnames/bind";

import styles from './Popper.module.scss'

const cx = classNames.bind(styles);


const Wrapper = ({children, className}) => {
    return (
        <>
            <div className={cx('container', className)}>            
                {children}
            </div>
        </>
    )

}

export default Wrapper
